import cn from "classnames";
import React, { FunctionComponent } from "react";

import { PropTypesOf } from "coral-framework/types";
import { BaseButton } from "coral-ui/components/v2";

import styles from "./HideButton.css";

interface Props extends Omit<PropTypesOf<typeof BaseButton>, "ref"> {
  hidden: boolean;
  enabled?: boolean;
}

const HideButton: FunctionComponent<Props> = ({
  hidden,
  enabled = true,
  className,
  ...rest
}) => (
  <BaseButton
    {...rest}
    className={cn(className, styles.root, {
      [styles.invert]: hidden,
    })}
    disabled={!enabled}
  >
    {hidden ? <span>Hidden</span> : <span>Hide</span>}
  </BaseButton>
);

export default HideButton;
