/* tslint:disable */
/* eslint-disable */
/* @relayHash 1752a0bf7610b4227ffe09709df70670 */

import { ConcreteRequest } from "relay-runtime";
export type REJECTION_REASON_CODE = "ABUSIVE" | "AD" | "BANNED_WORD" | "HARASSMENT_BULLYING" | "HATE_SPEECH" | "ILLEGAL_CONTENT" | "IRRELEVANT_CONTENT" | "MISINFORMATION" | "OFFENSIVE" | "OTHER" | "SPAM" | "%future added value";
export type USER_STATUS = "ACTIVE" | "BANNED" | "BIO_DISABLED" | "PREMOD" | "SUSPENDED" | "WARNED" | "%future added value";
export type UpdateUserBanInput = {
    userID: string;
    banSiteIDs?: Array<string> | null;
    unbanSiteIDs?: Array<string> | null;
    message: string;
    rejectExistingComments?: boolean | null;
    rejectionReason?: RejectCommentReasonInput | null;
    clientMutationId: string;
};
export type RejectCommentReasonInput = {
    code: REJECTION_REASON_CODE;
    legalGrounds?: string | null;
    detailedExplanation?: string | null;
    customReason?: string | null;
};
export type UpdateUserBanMutationVariables = {
    input: UpdateUserBanInput;
};
export type UpdateUserBanMutationResponse = {
    readonly updateUserBan: {
        readonly user: {
            readonly id: string;
            readonly status: {
                readonly current: ReadonlyArray<USER_STATUS>;
                readonly ban: {
                    readonly active: boolean;
                    readonly sites: ReadonlyArray<{
                        readonly id: string;
                    }> | null;
                };
            };
        };
    };
};
export type UpdateUserBanMutation = {
    readonly response: UpdateUserBanMutationResponse;
    readonly variables: UpdateUserBanMutationVariables;
};



/*
mutation UpdateUserBanMutation(
  $input: UpdateUserBanInput!
) {
  updateUserBan(input: $input) {
    user {
      id
      status {
        current
        ban {
          active
          sites {
            id
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v2 = [
        ({
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "UpdateUserBanPayload",
            "kind": "LinkedField",
            "name": "updateUserBan",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserStatus",
                            "kind": "LinkedField",
                            "name": "status",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "current",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "BanStatus",
                                    "kind": "LinkedField",
                                    "name": "ban",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "active",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Site",
                                            "kind": "LinkedField",
                                            "name": "sites",
                                            "plural": true,
                                            "selections": [
                                                (v1 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UpdateUserBanMutation",
            "selections": (v2 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UpdateUserBanMutation",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "id": "1752a0bf7610b4227ffe09709df70670",
            "metadata": {},
            "name": "UpdateUserBanMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '08116651014118c5c9a949e639fbcff9';
export default node;
