/* tslint:disable */
/* eslint-disable */
/* @relayHash 15c78c8bf232e783bf7dcd443e490acc */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DSA_METHOD_OF_REDRESS = "EMAIL" | "NONE" | "URL" | "%future added value";
export type GIF_MEDIA_SOURCE = "GIPHY" | "TENOR" | "%future added value";
export type MODERATION_MODE = "POST" | "PRE" | "SPECIFIC_SITES_PRE" | "%future added value";
export type UpdateSettingsInput = {
    settings: SettingsInput;
    clientMutationId: string;
};
export type SettingsInput = {
    live?: LiveConfigurationInput | null;
    moderation?: MODERATION_MODE | null;
    premoderateAllCommentsSites?: Array<string> | null;
    communityGuidelines?: SettingsCommunityGuidelinesInput | null;
    premodLinksEnable?: boolean | null;
    customCSSURL?: string | null;
    customFontsCSSURL?: string | null;
    disableDefaultFonts?: boolean | null;
    disableCommenting?: SettingsDisableCommentingInput | null;
    editCommentWindowLength?: number | null;
    organization?: SettingsOrganizationInput | null;
    closeCommenting?: SettingsCloseCommentingInput | null;
    wordList?: SettingsWordListInput | null;
    email?: SettingsEmailConfigurationInput | null;
    auth?: SettingsAuthInput | null;
    integrations?: SettingsExternalIntegrationsInput | null;
    recentCommentHistory?: RecentCommentHistoryConfigurationInput | null;
    charCount?: SettingsCharCountInput | null;
    stories?: StoryConfigurationInput | null;
    reaction?: ReactionConfigurationInput | null;
    featuredBy?: boolean | null;
    topCommenter?: TopCommenterConfigurationInput | null;
    newCommenter?: NewCommenterConfigurationInput | null;
    badges?: BadgeConfigurationInput | null;
    staff?: BadgeConfigurationInput | null;
    accountFeatures?: CommenterAccountFeaturesInput | null;
    slack?: SlackConfigurationInput | null;
    locale?: string | null;
    newCommenters?: NewCommentersConfigurationInput | null;
    premoderateSuspectWords?: boolean | null;
    rte?: RTEConfigurationInput | null;
    media?: MediaConfigurationInput | null;
    memberBios?: boolean | null;
    amp?: boolean | null;
    flattenReplies?: boolean | null;
    forReviewQueue?: boolean | null;
    externalProfileURL?: string | null;
    embeddedComments?: EmbeddedCommentsConfigurationInput | null;
    flairBadges?: FlairBadgeConfigurationInput | null;
    dsa?: DSAConfigurationInput | null;
    premoderateEmailAddress?: PremoderateEmailAddressConfigurationInput | null;
    protectedEmailDomains?: Array<string> | null;
    inPageNotifications?: InPageNotificationsConfigurationInput | null;
    showUnmoderatedCounts?: boolean | null;
    commentsNumberLimit?: CommentsNumberLimitConfigurationInput | null;
};
export type LiveConfigurationInput = {
    enabled?: boolean | null;
};
export type SettingsCommunityGuidelinesInput = {
    enabled?: boolean | null;
    content?: string | null;
};
export type SettingsDisableCommentingInput = {
    enabled?: boolean | null;
    message?: string | null;
};
export type SettingsOrganizationInput = {
    name?: string | null;
    contactEmail?: string | null;
    url?: string | null;
};
export type SettingsCloseCommentingInput = {
    auto?: boolean | null;
    timeout?: number | null;
    message?: string | null;
};
export type SettingsWordListInput = {
    banned?: Array<string> | null;
    suspect?: Array<string> | null;
};
export type SettingsEmailConfigurationInput = {
    enabled?: boolean | null;
    smtp?: SettingsSMTPInput | null;
    fromName?: string | null;
    fromEmail?: string | null;
};
export type SettingsSMTPInput = {
    secure?: boolean | null;
    host?: string | null;
    port?: number | null;
    authentication?: boolean | null;
    username?: string | null;
    password?: string | null;
};
export type SettingsAuthInput = {
    integrations?: SettingsAuthIntegrationsInput | null;
    sessionDuration: number;
};
export type SettingsAuthIntegrationsInput = {
    local?: SettingsLocalAuthIntegrationInput | null;
    remp?: SettingsRempAuthIntegrationInput | null;
    sso?: SettingsSSOAuthIntegrationInput | null;
    oidc?: SettingsOIDCAuthIntegrationInput | null;
    google?: SettingsGoogleAuthIntegrationInput | null;
    facebook?: SettingsFacebookAuthIntegrationInput | null;
};
export type SettingsLocalAuthIntegrationInput = {
    enabled?: boolean | null;
    allowRegistration?: boolean | null;
    targetFilter?: SettingsAuthenticationTargetFilterInput | null;
};
export type SettingsAuthenticationTargetFilterInput = {
    admin: boolean;
    stream: boolean;
};
export type SettingsRempAuthIntegrationInput = {
    enabled?: boolean | null;
    targetFilter?: SettingsAuthenticationTargetFilterInput | null;
};
export type SettingsSSOAuthIntegrationInput = {
    enabled?: boolean | null;
    allowRegistration?: boolean | null;
    targetFilter?: SettingsAuthenticationTargetFilterInput | null;
};
export type SettingsOIDCAuthIntegrationInput = {
    enabled?: boolean | null;
    allowRegistration?: boolean | null;
    targetFilter?: SettingsAuthenticationTargetFilterInput | null;
    name?: string | null;
    clientID?: string | null;
    clientSecret?: string | null;
    authorizationURL?: string | null;
    tokenURL?: string | null;
    jwksURI?: string | null;
    issuer?: string | null;
};
export type SettingsGoogleAuthIntegrationInput = {
    enabled?: boolean | null;
    allowRegistration?: boolean | null;
    targetFilter?: SettingsAuthenticationTargetFilterInput | null;
    clientID?: string | null;
    clientSecret?: string | null;
};
export type SettingsFacebookAuthIntegrationInput = {
    enabled?: boolean | null;
    allowRegistration?: boolean | null;
    targetFilter?: SettingsAuthenticationTargetFilterInput | null;
    clientID?: string | null;
    clientSecret?: string | null;
};
export type SettingsExternalIntegrationsInput = {
    akismet?: SettingsAkismetExternalIntegrationInput | null;
    perspective?: SettingsPerspectiveExternalIntegrationInput | null;
};
export type SettingsAkismetExternalIntegrationInput = {
    enabled?: boolean | null;
    ipBased?: boolean | null;
    key?: string | null;
    site?: string | null;
};
export type SettingsPerspectiveExternalIntegrationInput = {
    enabled?: boolean | null;
    endpoint?: string | null;
    key?: string | null;
    model?: string | null;
    threshold?: number | null;
    doNotStore?: boolean | null;
    sendFeedback?: boolean | null;
};
export type RecentCommentHistoryConfigurationInput = {
    enabled?: boolean | null;
    timeFrame?: number | null;
    triggerRejectionRate?: number | null;
};
export type SettingsCharCountInput = {
    enabled?: boolean | null;
    min?: number | null;
    max?: number | null;
};
export type StoryConfigurationInput = {
    scraping?: StoryScrapingConfigurationInput | null;
    disableLazy?: boolean | null;
};
export type StoryScrapingConfigurationInput = {
    enabled?: boolean | null;
    proxyURL?: string | null;
    customUserAgent?: string | null;
    authentication: boolean;
    username?: string | null;
    password?: string | null;
};
export type ReactionConfigurationInput = {
    icon?: string | null;
    iconActive?: string | null;
    label?: string | null;
    labelActive?: string | null;
    sortLabel?: string | null;
    color?: string | null;
};
export type TopCommenterConfigurationInput = {
    enabled?: boolean | null;
};
export type NewCommenterConfigurationInput = {
    enabled?: boolean | null;
};
export type BadgeConfigurationInput = {
    staffLabel?: string | null;
    adminLabel?: string | null;
    moderatorLabel?: string | null;
    memberLabel?: string | null;
};
export type CommenterAccountFeaturesInput = {
    changeUsername?: boolean | null;
    downloadComments?: boolean | null;
    deleteAccount?: boolean | null;
};
export type SlackConfigurationInput = {
    channels?: Array<SlackChannelConfigurationInput> | null;
};
export type SlackChannelConfigurationInput = {
    enabled?: boolean | null;
    name?: string | null;
    hookURL?: string | null;
    triggers?: SlackTriggersConfigurationInput | null;
};
export type SlackTriggersConfigurationInput = {
    reportedComments?: boolean | null;
    pendingComments?: boolean | null;
    featuredComments?: boolean | null;
    allComments?: boolean | null;
    staffComments?: boolean | null;
};
export type NewCommentersConfigurationInput = {
    premodEnabled?: boolean | null;
    approvedCommentsThreshold?: number | null;
    moderation?: NewCommentersModerationConfigInput | null;
};
export type NewCommentersModerationConfigInput = {
    mode?: MODERATION_MODE | null;
    premodSites?: Array<string> | null;
};
export type RTEConfigurationInput = {
    enabled: boolean;
    strikethrough: boolean;
    spoiler: boolean;
};
export type MediaConfigurationInput = {
    twitter?: TwitterMediaConfigurationInput | null;
    bluesky?: BlueskyMediaConfigurationInput | null;
    youtube?: YouTubeMediaConfigurationInput | null;
    gifs?: GifMediaConfigurationInput | null;
};
export type TwitterMediaConfigurationInput = {
    enabled?: boolean | null;
};
export type BlueskyMediaConfigurationInput = {
    enabled?: boolean | null;
};
export type YouTubeMediaConfigurationInput = {
    enabled?: boolean | null;
};
export type GifMediaConfigurationInput = {
    enabled?: boolean | null;
    provider?: GIF_MEDIA_SOURCE | null;
    maxRating?: string | null;
    key?: string | null;
};
export type EmbeddedCommentsConfigurationInput = {
    allowReplies?: boolean | null;
    oEmbedAllowedOrigins?: Array<string> | null;
};
export type FlairBadgeConfigurationInput = {
    flairBadgesEnabled?: boolean | null;
    flairBadgeURLs?: Array<string> | null;
};
export type DSAConfigurationInput = {
    enabled?: boolean | null;
    methodOfRedress?: DSAMethodOfRedressConfigurationInput | null;
};
export type DSAMethodOfRedressConfigurationInput = {
    method?: DSA_METHOD_OF_REDRESS | null;
    email?: string | null;
    url?: string | null;
};
export type PremoderateEmailAddressConfigurationInput = {
    tooManyPeriods?: TooManyPeriodsConfigInput | null;
    emailAliases?: EmailAliasesConfigInput | null;
};
export type TooManyPeriodsConfigInput = {
    enabled?: boolean | null;
};
export type EmailAliasesConfigInput = {
    enabled?: boolean | null;
};
export type InPageNotificationsConfigurationInput = {
    enabled?: boolean | null;
    floatingBellIndicator?: boolean | null;
};
export type CommentsNumberLimitConfigurationInput = {
    weeklyLimit?: number | null;
    weeklyWarningThreshold?: number | null;
};
export type UpdateSettingsMutationVariables = {
    input: UpdateSettingsInput;
};
export type UpdateSettingsMutationResponse = {
    readonly updateSettings: {
        readonly settings: {
            readonly auth: {
                readonly " $fragmentRefs": FragmentRefs<"AuthConfigContainer_auth">;
            };
            readonly email: {
                readonly " $fragmentRefs": FragmentRefs<"EmailConfigContainer_email">;
            };
            readonly " $fragmentRefs": FragmentRefs<"ModerationConfigContainer_settings" | "GeneralConfigContainer_settings" | "OrganizationConfigContainer_settings" | "WordListConfigContainer_settings" | "AdvancedConfigContainer_settings" | "SlackConfigContainer_settings">;
        } | null;
        readonly clientMutationId: string;
    };
};
export type UpdateSettingsMutation = {
    readonly response: UpdateSettingsMutationResponse;
    readonly variables: UpdateSettingsMutationVariables;
};



/*
mutation UpdateSettingsMutation(
  $input: UpdateSettingsInput!
) {
  updateSettings(input: $input) {
    settings {
      auth {
        ...AuthConfigContainer_auth
      }
      email {
        ...EmailConfigContainer_email
      }
      ...ModerationConfigContainer_settings
      ...GeneralConfigContainer_settings
      ...OrganizationConfigContainer_settings
      ...WordListConfigContainer_settings
      ...AdvancedConfigContainer_settings
      ...SlackConfigContainer_settings
      id
    }
    clientMutationId
  }
}

fragment AdvancedConfigContainer_settings on Settings {
  customFontsCSSURL
  customCSSURL
  embeddedComments {
    allowReplies
    oEmbedAllowedOrigins
  }
  live {
    enabled
  }
  stories {
    scraping {
      enabled
      proxyURL
      customUserAgent
      authentication
      username
      password
    }
    disableLazy
  }
  ...CommentStreamLiveUpdatesContainer_settings
  amp
  forReviewQueue
}

fragment AnnouncementConfigContainer_settings on Settings {
  announcement {
    content
    duration
    createdAt
    id
  }
}

fragment AuthConfigContainer_auth on Auth {
  integrations {
    facebook {
      enabled
      allowRegistration
      targetFilter {
        admin
        stream
      }
      clientID
      clientSecret
    }
    google {
      enabled
      allowRegistration
      targetFilter {
        admin
        stream
      }
      clientID
      clientSecret
    }
    sso {
      enabled
      allowRegistration
      targetFilter {
        admin
        stream
      }
    }
    local {
      enabled
      allowRegistration
      targetFilter {
        admin
        stream
      }
    }
    remp {
      enabled
      targetFilter {
        admin
        stream
      }
    }
    oidc {
      enabled
      allowRegistration
      targetFilter {
        admin
        stream
      }
      name
      clientID
      clientSecret
      authorizationURL
      tokenURL
      jwksURI
      issuer
    }
  }
  sessionDuration
  ...FacebookConfigContainer_auth
  ...GoogleConfigContainer_auth
  ...SSOConfigContainer_auth
  ...OIDCConfigContainer_auth
}

fragment CommentStreamLiveUpdatesContainer_settings on Settings {
  live {
    configurable
  }
}

fragment DSAConfigContainer_settings on Settings {
  dsa {
    enabled
    methodOfRedress {
      method
      url
      email
    }
  }
}

fragment EmailConfigContainer_email on EmailConfiguration {
  enabled
  fromName
  fromEmail
  smtp {
    host
    port
    secure
    authentication
    username
    password
  }
}

fragment EmailDomainConfigContainer_settings on Settings {
  protectedEmailDomains
  ...EmailDomainTableContainer_settings
}

fragment EmailDomainTableContainer_settings on Settings {
  emailDomainModeration {
    domain
    id
    newUserModeration
  }
}

fragment ExternalLinksConfigContainer_settings on Settings {
  featureFlags
}

fragment FacebookConfigContainer_auth on Auth {
  integrations {
    facebook {
      callbackURL
    }
  }
}

fragment FlairBadgeConfigContainer_settings on Settings {
  flairBadges {
    flairBadgesEnabled
    badges {
      name
      url
    }
  }
}

fragment GeneralConfigContainer_settings on Settings {
  ...AnnouncementConfigContainer_settings
  flattenReplies
  inPageNotifications {
    enabled
    floatingBellIndicator
  }
  locale
  dsa {
    enabled
    methodOfRedress {
      method
      url
      email
    }
  }
  ...DSAConfigContainer_settings
  communityGuidelines {
    enabled
    content
  }
  charCount {
    enabled
    min
    max
  }
  editCommentWindowLength
  closeCommenting {
    message
    auto
    timeout
  }
  disableCommenting {
    enabled
    message
  }
  featuredBy
  reaction {
    label
    labelActive
    sortLabel
    icon
    iconActive
  }
  badges {
    staffLabel
    adminLabel
    moderatorLabel
    memberLabel
  }
  newCommenter {
    enabled
  }
  topCommenter {
    enabled
  }
  flairBadges {
    flairBadgesEnabled
  }
  ...FlairBadgeConfigContainer_settings
  rte {
    enabled
    strikethrough
    spoiler
  }
  media {
    twitter {
      enabled
    }
    bluesky {
      enabled
    }
    youtube {
      enabled
    }
    gifs {
      enabled
      maxRating
      key
      provider
    }
  }
  memberBios
  ...ReactionConfigContainer_settings
}

fragment GoogleConfigContainer_auth on Auth {
  integrations {
    google {
      callbackURL
    }
  }
}

fragment ModerationConfigContainer_settings on Settings {
  integrations {
    akismet {
      enabled
      ipBased
      key
      site
    }
    perspective {
      enabled
      endpoint
      key
      model
      threshold
      doNotStore
      sendFeedback
    }
  }
  showUnmoderatedCounts
  moderation
  premodLinksEnable
  premoderateSuspectWords
  premoderateAllCommentsSites
  ...PreModerationConfigContainer_settings
  recentCommentHistory {
    enabled
    timeFrame
    triggerRejectionRate
  }
  newCommenters {
    premodEnabled
    approvedCommentsThreshold
    moderation {
      mode
      premodSites
    }
  }
  ...NewCommentersConfigContainer_settings
  ...EmailDomainConfigContainer_settings
  protectedEmailDomains
  externalProfileURL
  ...ExternalLinksConfigContainer_settings
  premoderateEmailAddress {
    tooManyPeriods {
      enabled
    }
    emailAliases {
      enabled
    }
  }
  commentsNumberLimit {
    weeklyLimit
    weeklyWarningThreshold
  }
}

fragment NewCommentersConfigContainer_settings on Settings {
  multisite
}

fragment OIDCConfigContainer_auth on Auth {
  integrations {
    oidc {
      callbackURL
    }
  }
}

fragment OrganizationConfigContainer_settings on Settings {
  organization {
    name
    url
    contactEmail
  }
}

fragment PreModerationConfigContainer_settings on Settings {
  multisite
}

fragment ReactionConfigContainer_settings on Settings {
  reaction {
    icon
    iconActive
  }
}

fragment SSOConfigContainer_auth on Auth {
  ...SSOConfig_formValues
}

fragment SSOConfig_formValues on Auth {
  integrations {
    sso {
      enabled
      allowRegistration
      targetFilter {
        admin
        stream
      }
    }
  }
}

fragment SlackConfigContainer_settings on Settings {
  slack {
    channels {
      enabled
      name
      hookURL
      triggers {
        reportedComments
        pendingComments
        featuredComments
        allComments
        staffComments
      }
    }
  }
}

fragment WordListConfigContainer_settings on Settings {
  wordList {
    suspect
    banned
  }
  premoderateSuspectWords
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any)
    ], v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
    } as any), v3 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enabled",
        "storageKey": null
    } as any), v4 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "allowRegistration",
        "storageKey": null
    } as any), v5 = ({
        "alias": null,
        "args": null,
        "concreteType": "AuthenticationTargetFilter",
        "kind": "LinkedField",
        "name": "targetFilter",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "admin",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "stream",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v6 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientID",
        "storageKey": null
    } as any), v7 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientSecret",
        "storageKey": null
    } as any), v8 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "callbackURL",
        "storageKey": null
    } as any), v9 = [
        (v3 /*: any*/),
        (v4 /*: any*/),
        (v5 /*: any*/),
        (v6 /*: any*/),
        (v7 /*: any*/),
        (v8 /*: any*/)
    ], v10 = [
        (v3 /*: any*/),
        (v4 /*: any*/),
        (v5 /*: any*/)
    ], v11 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any), v12 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "authentication",
        "storageKey": null
    } as any), v13 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
    } as any), v14 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "password",
        "storageKey": null
    } as any), v15 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
    } as any), v16 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v17 = [
        (v3 /*: any*/)
    ], v18 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "content",
        "storageKey": null
    } as any), v19 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
    } as any), v20 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UpdateSettingsMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateSettingsPayload",
                    "kind": "LinkedField",
                    "name": "updateSettings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Settings",
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Auth",
                                    "kind": "LinkedField",
                                    "name": "auth",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "args": null,
                                            "kind": "FragmentSpread",
                                            "name": "AuthConfigContainer_auth"
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "EmailConfiguration",
                                    "kind": "LinkedField",
                                    "name": "email",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "args": null,
                                            "kind": "FragmentSpread",
                                            "name": "EmailConfigContainer_email"
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "ModerationConfigContainer_settings"
                                },
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "GeneralConfigContainer_settings"
                                },
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "OrganizationConfigContainer_settings"
                                },
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "WordListConfigContainer_settings"
                                },
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "AdvancedConfigContainer_settings"
                                },
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "SlackConfigContainer_settings"
                                }
                            ],
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UpdateSettingsMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateSettingsPayload",
                    "kind": "LinkedField",
                    "name": "updateSettings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Settings",
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Auth",
                                    "kind": "LinkedField",
                                    "name": "auth",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "AuthIntegrations",
                                            "kind": "LinkedField",
                                            "name": "integrations",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "FacebookAuthIntegration",
                                                    "kind": "LinkedField",
                                                    "name": "facebook",
                                                    "plural": false,
                                                    "selections": (v9 /*: any*/),
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "GoogleAuthIntegration",
                                                    "kind": "LinkedField",
                                                    "name": "google",
                                                    "plural": false,
                                                    "selections": (v9 /*: any*/),
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "SSOAuthIntegration",
                                                    "kind": "LinkedField",
                                                    "name": "sso",
                                                    "plural": false,
                                                    "selections": (v10 /*: any*/),
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "LocalAuthIntegration",
                                                    "kind": "LinkedField",
                                                    "name": "local",
                                                    "plural": false,
                                                    "selections": (v10 /*: any*/),
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "RempAuthIntegration",
                                                    "kind": "LinkedField",
                                                    "name": "remp",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        (v5 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "OIDCAuthIntegration",
                                                    "kind": "LinkedField",
                                                    "name": "oidc",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        (v4 /*: any*/),
                                                        (v5 /*: any*/),
                                                        (v11 /*: any*/),
                                                        (v6 /*: any*/),
                                                        (v7 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "authorizationURL",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "tokenURL",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "jwksURI",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "issuer",
                                                            "storageKey": null
                                                        },
                                                        (v8 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "sessionDuration",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "EmailConfiguration",
                                    "kind": "LinkedField",
                                    "name": "email",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "fromName",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "fromEmail",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "SMTP",
                                            "kind": "LinkedField",
                                            "name": "smtp",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "host",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "port",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "secure",
                                                    "storageKey": null
                                                },
                                                (v12 /*: any*/),
                                                (v13 /*: any*/),
                                                (v14 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ExternalIntegrations",
                                    "kind": "LinkedField",
                                    "name": "integrations",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "AkismetExternalIntegration",
                                            "kind": "LinkedField",
                                            "name": "akismet",
                                            "plural": false,
                                            "selections": [
                                                (v3 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "ipBased",
                                                    "storageKey": null
                                                },
                                                (v15 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "site",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PerspectiveExternalIntegration",
                                            "kind": "LinkedField",
                                            "name": "perspective",
                                            "plural": false,
                                            "selections": [
                                                (v3 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "endpoint",
                                                    "storageKey": null
                                                },
                                                (v15 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "model",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "threshold",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "doNotStore",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "sendFeedback",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "showUnmoderatedCounts",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "moderation",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "premodLinksEnable",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "premoderateSuspectWords",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "premoderateAllCommentsSites",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "multisite",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "RecentCommentHistoryConfiguration",
                                    "kind": "LinkedField",
                                    "name": "recentCommentHistory",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "timeFrame",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "triggerRejectionRate",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "NewCommentersConfiguration",
                                    "kind": "LinkedField",
                                    "name": "newCommenters",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "premodEnabled",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "approvedCommentsThreshold",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "NewCommentersModerationConfig",
                                            "kind": "LinkedField",
                                            "name": "moderation",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "mode",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "premodSites",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "protectedEmailDomains",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "EmailDomain",
                                    "kind": "LinkedField",
                                    "name": "emailDomainModeration",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "domain",
                                            "storageKey": null
                                        },
                                        (v16 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "newUserModeration",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "externalProfileURL",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "featureFlags",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PremoderateEmailAddressConfiguration",
                                    "kind": "LinkedField",
                                    "name": "premoderateEmailAddress",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "TooManyPeriodsConfig",
                                            "kind": "LinkedField",
                                            "name": "tooManyPeriods",
                                            "plural": false,
                                            "selections": (v17 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "EmailAliasesConfig",
                                            "kind": "LinkedField",
                                            "name": "emailAliases",
                                            "plural": false,
                                            "selections": (v17 /*: any*/),
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CommentsNumberLimitConfiguration",
                                    "kind": "LinkedField",
                                    "name": "commentsNumberLimit",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "weeklyLimit",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "weeklyWarningThreshold",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Announcement",
                                    "kind": "LinkedField",
                                    "name": "announcement",
                                    "plural": false,
                                    "selections": [
                                        (v18 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "duration",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "createdAt",
                                            "storageKey": null
                                        },
                                        (v16 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "flattenReplies",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "InPageNotificationsConfiguration",
                                    "kind": "LinkedField",
                                    "name": "inPageNotifications",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "floatingBellIndicator",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "locale",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "DSAConfiguration",
                                    "kind": "LinkedField",
                                    "name": "dsa",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "DSAMethodOfRedressConfiguration",
                                            "kind": "LinkedField",
                                            "name": "methodOfRedress",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "method",
                                                    "storageKey": null
                                                },
                                                (v19 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "email",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CommunityGuidelines",
                                    "kind": "LinkedField",
                                    "name": "communityGuidelines",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v18 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CharCount",
                                    "kind": "LinkedField",
                                    "name": "charCount",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "min",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "max",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "editCommentWindowLength",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CloseCommenting",
                                    "kind": "LinkedField",
                                    "name": "closeCommenting",
                                    "plural": false,
                                    "selections": [
                                        (v20 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "auto",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "timeout",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "DisableCommenting",
                                    "kind": "LinkedField",
                                    "name": "disableCommenting",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v20 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "featuredBy",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ReactionConfiguration",
                                    "kind": "LinkedField",
                                    "name": "reaction",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "label",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "labelActive",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "sortLabel",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "icon",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "iconActive",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "BadgeConfiguration",
                                    "kind": "LinkedField",
                                    "name": "badges",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "staffLabel",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "adminLabel",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "moderatorLabel",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "memberLabel",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "NewCommenterConfiguration",
                                    "kind": "LinkedField",
                                    "name": "newCommenter",
                                    "plural": false,
                                    "selections": (v17 /*: any*/),
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "TopCommenterConfiguration",
                                    "kind": "LinkedField",
                                    "name": "topCommenter",
                                    "plural": false,
                                    "selections": (v17 /*: any*/),
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "FlairBadgeConfiguration",
                                    "kind": "LinkedField",
                                    "name": "flairBadges",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "flairBadgesEnabled",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "FlairBadge",
                                            "kind": "LinkedField",
                                            "name": "badges",
                                            "plural": true,
                                            "selections": [
                                                (v11 /*: any*/),
                                                (v19 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "RTEConfiguration",
                                    "kind": "LinkedField",
                                    "name": "rte",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "strikethrough",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "spoiler",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "MediaConfiguration",
                                    "kind": "LinkedField",
                                    "name": "media",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "TwitterMediaConfiguration",
                                            "kind": "LinkedField",
                                            "name": "twitter",
                                            "plural": false,
                                            "selections": (v17 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "BlueskyMediaConfiguration",
                                            "kind": "LinkedField",
                                            "name": "bluesky",
                                            "plural": false,
                                            "selections": (v17 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "YouTubeMediaConfiguration",
                                            "kind": "LinkedField",
                                            "name": "youtube",
                                            "plural": false,
                                            "selections": (v17 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "GifMediaConfiguration",
                                            "kind": "LinkedField",
                                            "name": "gifs",
                                            "plural": false,
                                            "selections": [
                                                (v3 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "maxRating",
                                                    "storageKey": null
                                                },
                                                (v15 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "provider",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "memberBios",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Organization",
                                    "kind": "LinkedField",
                                    "name": "organization",
                                    "plural": false,
                                    "selections": [
                                        (v11 /*: any*/),
                                        (v19 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "contactEmail",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "WordList",
                                    "kind": "LinkedField",
                                    "name": "wordList",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "suspect",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "banned",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "customFontsCSSURL",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "customCSSURL",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "EmbeddedCommentsConfiguration",
                                    "kind": "LinkedField",
                                    "name": "embeddedComments",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "allowReplies",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "oEmbedAllowedOrigins",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LiveConfiguration",
                                    "kind": "LinkedField",
                                    "name": "live",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "configurable",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "StoryConfiguration",
                                    "kind": "LinkedField",
                                    "name": "stories",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "StoryScrapingConfiguration",
                                            "kind": "LinkedField",
                                            "name": "scraping",
                                            "plural": false,
                                            "selections": [
                                                (v3 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "proxyURL",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "customUserAgent",
                                                    "storageKey": null
                                                },
                                                (v12 /*: any*/),
                                                (v13 /*: any*/),
                                                (v14 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "disableLazy",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "amp",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "forReviewQueue",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SlackConfiguration",
                                    "kind": "LinkedField",
                                    "name": "slack",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "SlackChannel",
                                            "kind": "LinkedField",
                                            "name": "channels",
                                            "plural": true,
                                            "selections": [
                                                (v3 /*: any*/),
                                                (v11 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "hookURL",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "SlackChannelTriggers",
                                                    "kind": "LinkedField",
                                                    "name": "triggers",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "reportedComments",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "pendingComments",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "featuredComments",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "allComments",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "staffComments",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                (v16 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "15c78c8bf232e783bf7dcd443e490acc",
            "metadata": {},
            "name": "UpdateSettingsMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'd60b8055f71e8034cad1a79eb52a6223';
export default node;
