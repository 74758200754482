/* tslint:disable */
/* eslint-disable */
/* @relayHash d9cf648e22390db0632f6ffe16ec5f4d */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MEMBER" | "MODERATOR" | "READER" | "STAFF" | "%future added value";
export type SectionFilter = {
    name?: string | null;
};
export type ModerateContainerQueryVariables = {
    storyID?: string | null;
    includeStory: boolean;
    siteID?: string | null;
    section?: SectionFilter | null;
};
export type ModerateContainerQueryResponse = {
    readonly settings: {
        readonly " $fragmentRefs": FragmentRefs<"ModerateSearchBarContainer_settings" | "ModerateNavigationContainer_settings">;
    };
    readonly story?: {
        readonly site: {
            readonly id: string;
            readonly canModerate: boolean;
        };
        readonly isArchived: boolean;
        readonly isArchiving: boolean;
        readonly " $fragmentRefs": FragmentRefs<"ModerateNavigationContainer_story" | "ModerateSearchBarContainer_story">;
    } | null;
    readonly moderationQueues: {
        readonly " $fragmentRefs": FragmentRefs<"ModerateNavigationContainer_moderationQueues">;
    };
    readonly viewer: {
        readonly id: string;
        readonly role: USER_ROLE;
        readonly moderationScopes: {
            readonly scoped: boolean;
            readonly sites: ReadonlyArray<{
                readonly id: string;
            }> | null;
        } | null;
        readonly " $fragmentRefs": FragmentRefs<"SiteSelectorContainer_viewer">;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"SiteSelectorContainer_query" | "SectionSelectorContainer_query">;
};
export type ModerateContainerQuery = {
    readonly response: ModerateContainerQueryResponse;
    readonly variables: ModerateContainerQueryVariables;
};



/*
query ModerateContainerQuery(
  $storyID: ID
  $includeStory: Boolean!
  $siteID: ID
  $section: SectionFilter
) {
  ...SiteSelectorContainer_query
  ...SectionSelectorContainer_query
  settings {
    ...ModerateSearchBarContainer_settings
    ...ModerateNavigationContainer_settings
    id
  }
  story(id: $storyID) @include(if: $includeStory) {
    ...ModerateNavigationContainer_story
    ...ModerateSearchBarContainer_story
    site {
      id
      canModerate
    }
    isArchived
    isArchiving
    id
  }
  moderationQueues(storyID: $storyID, siteID: $siteID, section: $section) {
    ...ModerateNavigationContainer_moderationQueues
  }
  viewer {
    ...SiteSelectorContainer_viewer
    id
    role
    moderationScopes {
      scoped
      sites {
        id
      }
    }
  }
}

fragment ModerateNavigationContainer_moderationQueues on ModerationQueues {
  unmoderated {
    count
    id
  }
  reported {
    count
    id
  }
  pending {
    count
    id
  }
}

fragment ModerateNavigationContainer_settings on Settings {
  moderation
  forReviewQueue
  showUnmoderatedCounts
}

fragment ModerateNavigationContainer_story on Story {
  id
  isArchiving
  isArchived
}

fragment ModerateSearchBarContainer_settings on Settings {
  multisite
  featureFlags
}

fragment ModerateSearchBarContainer_story on Story {
  id
  site {
    name
    id
  }
  metadata {
    title
    author
    section
  }
}

fragment SectionSelectorContainer_query on Query {
  sections
  settings {
    featureFlags
    id
  }
}

fragment SiteSelectorContainer_query on Query {
  sites(first: 10) {
    edges {
      node {
        id
        ...SiteSelectorSite_site
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment SiteSelectorContainer_viewer on User {
  moderationScopes {
    scoped
    sites {
      id
      ...SiteSelectorSite_site
    }
  }
}

fragment SiteSelectorSite_site on Site {
  name
  id
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "includeStory"
    } as any), v1 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "section"
    } as any), v2 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "siteID"
    } as any), v3 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "storyID"
    } as any), v4 = [
        ({
            "kind": "Variable",
            "name": "section",
            "variableName": "section"
        } as any),
        ({
            "kind": "Variable",
            "name": "siteID",
            "variableName": "siteID"
        } as any),
        ({
            "kind": "Variable",
            "name": "storyID",
            "variableName": "storyID"
        } as any)
    ], v5 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v6 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "role",
        "storageKey": null
    } as any), v7 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "scoped",
        "storageKey": null
    } as any), v8 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "storyID"
        } as any)
    ], v9 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "canModerate",
        "storageKey": null
    } as any), v10 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isArchived",
        "storageKey": null
    } as any), v11 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isArchiving",
        "storageKey": null
    } as any), v12 = [
        ({
            "kind": "Literal",
            "name": "first",
            "value": 10
        } as any)
    ], v13 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any), v14 = [
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
        } as any),
        (v5 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "ModerateContainerQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ModerateSearchBarContainer_settings"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ModerateNavigationContainer_settings"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "concreteType": "ModerationQueues",
                    "kind": "LinkedField",
                    "name": "moderationQueues",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ModerateNavigationContainer_moderationQueues"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserModerationScopes",
                            "kind": "LinkedField",
                            "name": "moderationScopes",
                            "plural": false,
                            "selections": [
                                (v7 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "sites",
                                    "plural": true,
                                    "selections": [
                                        (v5 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "SiteSelectorContainer_viewer"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SiteSelectorContainer_query"
                },
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SectionSelectorContainer_query"
                },
                {
                    "condition": "includeStory",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": (v8 /*: any*/),
                            "concreteType": "Story",
                            "kind": "LinkedField",
                            "name": "story",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "site",
                                    "plural": false,
                                    "selections": [
                                        (v5 /*: any*/),
                                        (v9 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v10 /*: any*/),
                                (v11 /*: any*/),
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "ModerateNavigationContainer_story"
                                },
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "ModerateSearchBarContainer_story"
                                }
                            ],
                            "storageKey": null
                        }
                    ]
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v3 /*: any*/),
                (v0 /*: any*/),
                (v2 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Operation",
            "name": "ModerateContainerQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v12 /*: any*/),
                    "concreteType": "SitesConnection",
                    "kind": "LinkedField",
                    "name": "sites",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SiteEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v5 /*: any*/),
                                        (v13 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": "sites(first:10)"
                },
                {
                    "alias": null,
                    "args": (v12 /*: any*/),
                    "filters": [
                        "query"
                    ],
                    "handle": "connection",
                    "key": "SitesConfig_sites",
                    "kind": "LinkedHandle",
                    "name": "sites"
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sections",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "featureFlags",
                            "storageKey": null
                        },
                        (v5 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "multisite",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "moderation",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "forReviewQueue",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "showUnmoderatedCounts",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "concreteType": "ModerationQueues",
                    "kind": "LinkedField",
                    "name": "moderationQueues",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ModerationQueue",
                            "kind": "LinkedField",
                            "name": "unmoderated",
                            "plural": false,
                            "selections": (v14 /*: any*/),
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ModerationQueue",
                            "kind": "LinkedField",
                            "name": "reported",
                            "plural": false,
                            "selections": (v14 /*: any*/),
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ModerationQueue",
                            "kind": "LinkedField",
                            "name": "pending",
                            "plural": false,
                            "selections": (v14 /*: any*/),
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserModerationScopes",
                            "kind": "LinkedField",
                            "name": "moderationScopes",
                            "plural": false,
                            "selections": [
                                (v7 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "sites",
                                    "plural": true,
                                    "selections": [
                                        (v5 /*: any*/),
                                        (v13 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v5 /*: any*/),
                        (v6 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "condition": "includeStory",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": (v8 /*: any*/),
                            "concreteType": "Story",
                            "kind": "LinkedField",
                            "name": "story",
                            "plural": false,
                            "selections": [
                                (v5 /*: any*/),
                                (v11 /*: any*/),
                                (v10 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "site",
                                    "plural": false,
                                    "selections": [
                                        (v13 /*: any*/),
                                        (v5 /*: any*/),
                                        (v9 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "StoryMetadata",
                                    "kind": "LinkedField",
                                    "name": "metadata",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "title",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "author",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "section",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ]
                }
            ]
        },
        "params": {
            "id": "d9cf648e22390db0632f6ffe16ec5f4d",
            "metadata": {},
            "name": "ModerateContainerQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'ec2229c387868b3972d683d4f7a9b14c';
export default node;
