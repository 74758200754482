/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MEMBER" | "MODERATOR" | "READER" | "STAFF" | "%future added value";
export type NavigationContainer_viewer = {
    readonly role: USER_ROLE;
    readonly moderationScopes: {
        readonly sites: ReadonlyArray<{
            readonly id: string;
        }> | null;
    } | null;
    readonly " $refType": "NavigationContainer_viewer";
};
export type NavigationContainer_viewer$data = NavigationContainer_viewer;
export type NavigationContainer_viewer$key = {
    readonly " $data"?: NavigationContainer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"NavigationContainer_viewer">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NavigationContainer_viewer",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "UserModerationScopes",
            "kind": "LinkedField",
            "name": "moderationScopes",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Site",
                    "kind": "LinkedField",
                    "name": "sites",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
} as any);
(node as any).hash = 'fd00b8fbbfdb8bfc50a2af6ee1bf246c';
export default node;
