import React, { FunctionComponent } from "react";

// https://www.streamlinehq.com/icons/material-symbols-outlined-line?search=favorite&icon=ico_IcJ0eszeavtbLkJy
const FavoriteIcon: FunctionComponent = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
    >
      <g id="favorite">
        <path
          id="Vector"
          d="m12 20.9749 -1.025 -0.925c-1.76285 -1.61865 -3.22015 -3.015 -4.372 -4.189 -1.152 -1.174 -2.069665 -2.2235 -2.753 -3.1485 -0.683335 -0.925 -1.1625 -1.7625 -1.4375 -2.5125 -0.275 -0.75 -0.4125 -1.50835 -0.4125 -2.275 0 -1.5025 0.504165 -2.75735 1.5125 -3.7645 1.008335 -1.007 2.25415 -1.5105 3.7375 -1.5105 0.95 0 1.82915 0.225 2.6375 0.675 0.80835 0.45 1.5125 1.1 2.1125 1.95 0.7 -0.9 1.44165 -1.5625 2.225 -1.9875 0.78335 -0.425 1.625 -0.6375 2.525 -0.6375 1.48335 0 2.72915 0.5035 3.7375 1.5105 1.00835 1.00715 1.5125 2.262 1.5125 3.7645 0 0.76665 -0.1375 1.525 -0.4125 2.275 -0.275 0.75 -0.75415 1.5875 -1.4375 2.5125 -0.68335 0.925 -1.601 1.9745 -2.753 3.1485 -1.15185 1.174 -2.60915 2.57035 -4.372 4.189l-1.025 0.925Zm0 -1.975c1.68735 -1.55 3.07585 -2.87915 4.1655 -3.9875 1.08965 -1.10835 1.95535 -2.07915 2.597 -2.9125 0.64165 -0.83335 1.09165 -1.57615 1.35 -2.2285 0.25835 -0.65215 0.3875 -1.29985 0.3875 -1.943 0 -1.10235 -0.35 -2.00765 -1.05 -2.716 -0.7 -0.70833 -1.59815 -1.0625 -2.6945 -1.0625 -0.85865 0 -1.6534 0.2625 -2.38425 0.7875 -0.73085 0.525 -1.32125 1.2625 -1.77125 2.2125h-1.225c-0.43335 -0.93335 -1.0154 -1.66665 -1.74625 -2.2 -0.73085 -0.53333 -1.5256 -0.8 -2.38425 -0.8 -1.09635 0 -1.9945 0.35417 -2.6945 1.0625 -0.7 0.70835 -1.05 1.61515 -1.05 2.7205 0 0.64465 0.129165 1.29615 0.3875 1.9545s0.708335 1.40835 1.35 2.25c0.64165 0.84165 1.5125 1.8125 2.6125 2.9125 1.1 1.1 2.48335 2.41665 4.15 3.95Z"
          fill="currentColor"
          // strokeLinecap="round"
          // strokeLinejoin="round"
          strokeWidth="0.8"
        ></path>
      </g>
    </svg>
  );
};

export default FavoriteIcon;
