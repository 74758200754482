import cn from "classnames";
import React, { FunctionComponent } from "react";

import CLASSES from "coral-stream/classes";
import styles from "./TombstoneWrapper.css";

interface Props {
  noBottomBorder?: boolean;
  children?: React.ReactNode;
}

const TombstoneWrapper: FunctionComponent<Props> = ({
  noBottomBorder,
  children,
}) => {
  const className = noBottomBorder
    ? cn(styles.root, CLASSES.tombstoneWrapper)
    : cn(styles.root, styles.border, CLASSES.tombstoneWrapper);
  return <div className={className}>{children}</div>;
};

export default TombstoneWrapper;
