/* tslint:disable */
/* eslint-disable */
/* @relayHash 6b112a5c4b277d027c4ce66c526c7b3a */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MEMBER" | "MODERATOR" | "READER" | "STAFF" | "%future added value";
export type USER_STATUS_FILTER = "ACTIVE" | "BANNED" | "BIO_DISABLED" | "PREMOD" | "SITE_BANNED" | "SUSPENDED" | "WARNED" | "%future added value";
export type UserTableContainerPaginationQueryVariables = {
    count: number;
    cursor?: unknown | null;
    roleFilter?: USER_ROLE | null;
    statusFilter?: USER_STATUS_FILTER | null;
    searchFilter?: string | null;
};
export type UserTableContainerPaginationQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"UserTableContainer_query">;
};
export type UserTableContainerPaginationQuery = {
    readonly response: UserTableContainerPaginationQueryResponse;
    readonly variables: UserTableContainerPaginationQueryVariables;
};



/*
query UserTableContainerPaginationQuery(
  $count: Int!
  $cursor: Cursor
  $roleFilter: USER_ROLE
  $statusFilter: USER_STATUS_FILTER
  $searchFilter: String
) {
  ...UserTableContainer_query_4gvvZy
}

fragment InviteUsersContainer_settings on Settings {
  email {
    enabled
  }
  auth {
    integrations {
      local {
        enabled
        allowRegistration
        targetFilter {
          admin
        }
      }
    }
  }
}

fragment InviteUsersContainer_viewer on User {
  role
}

fragment SiteRoleActions_user on User {
  id
  username
  role
  membershipScopes {
    scoped
    siteIDs
    sites {
      id
      name
    }
  }
  moderationScopes {
    scoped
    siteIDs
    sites {
      id
      name
    }
  }
}

fragment SiteRoleActions_viewer on User {
  id
  role
  moderationScopes {
    siteIDs
    sites {
      id
      name
    }
  }
}

fragment UserRoleChangeContainer_settings on Settings {
  multisite
}

fragment UserRoleChangeContainer_user on User {
  id
  username
  role
  moderationScopes {
    scoped
    sites {
      id
      name
    }
  }
  membershipScopes {
    scoped
    sites {
      id
      name
    }
  }
  ...SiteRoleActions_user
}

fragment UserRoleChangeContainer_viewer on User {
  id
  role
  moderationScopes {
    scoped
    sites {
      id
    }
  }
  ...SiteRoleActions_viewer
}

fragment UserRowContainer_settings on Settings {
  ...UserStatusChangeContainer_settings
  ...UserRoleChangeContainer_settings
}

fragment UserRowContainer_user on User {
  ...UserStatusChangeContainer_user
  ...UserRoleChangeContainer_user
  id
  username
  email
  createdAt
  deletedAt
}

fragment UserRowContainer_viewer on User {
  ...UserStatusChangeContainer_viewer
  ...UserRoleChangeContainer_viewer
}

fragment UserStatusChangeContainer_settings on Settings {
  organization {
    name
  }
  emailDomainModeration {
    domain
    newUserModeration
    id
  }
  multisite
  protectedEmailDomains
}

fragment UserStatusChangeContainer_user on User {
  id
  role
  username
  email
  moderationScopes {
    scoped
  }
  status {
    ban {
      active
      sites {
        id
        name
      }
    }
    suspension {
      active
    }
    premod {
      active
    }
    warning {
      active
    }
  }
  ...UserStatusContainer_user
}

fragment UserStatusChangeContainer_viewer on User {
  id
  role
  moderationScopes {
    scoped
    sites {
      id
      name
    }
  }
}

fragment UserStatusContainer_user on User {
  status {
    current
    ban {
      sites {
        id
      }
    }
  }
}

fragment UserTableContainer_query_4gvvZy on Query {
  viewer {
    ...UserRowContainer_viewer
    ...InviteUsersContainer_viewer
    id
  }
  settings {
    multisite
    featureFlags
    ...InviteUsersContainer_settings
    ...UserRowContainer_settings
    id
  }
  users(first: $count, after: $cursor, role: $roleFilter, status: $statusFilter, query: $searchFilter) {
    edges {
      node {
        id
        ...UserRowContainer_user
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "count"
    } as any), v1 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "cursor"
    } as any), v2 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "roleFilter"
    } as any), v3 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "searchFilter"
    } as any), v4 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "statusFilter"
    } as any), v5 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v6 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "role",
        "storageKey": null
    } as any), v7 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any), v8 = ({
        "alias": null,
        "args": null,
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "sites",
        "plural": true,
        "selections": [
            (v5 /*: any*/),
            (v7 /*: any*/)
        ],
        "storageKey": null
    } as any), v9 = [
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scoped",
            "storageKey": null
        } as any),
        (v8 /*: any*/),
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "siteIDs",
            "storageKey": null
        } as any)
    ], v10 = ({
        "alias": null,
        "args": null,
        "concreteType": "UserModerationScopes",
        "kind": "LinkedField",
        "name": "moderationScopes",
        "plural": false,
        "selections": (v9 /*: any*/),
        "storageKey": null
    } as any), v11 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enabled",
        "storageKey": null
    } as any), v12 = [
        ({
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any),
        ({
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any),
        ({
            "kind": "Variable",
            "name": "query",
            "variableName": "searchFilter"
        } as any),
        ({
            "kind": "Variable",
            "name": "role",
            "variableName": "roleFilter"
        } as any),
        ({
            "kind": "Variable",
            "name": "status",
            "variableName": "statusFilter"
        } as any)
    ], v13 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
    } as any), v14 = [
        (v13 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v4 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "UserTableContainerPaginationQuery",
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "count",
                            "variableName": "count"
                        },
                        {
                            "kind": "Variable",
                            "name": "cursor",
                            "variableName": "cursor"
                        },
                        {
                            "kind": "Variable",
                            "name": "roleFilter",
                            "variableName": "roleFilter"
                        },
                        {
                            "kind": "Variable",
                            "name": "searchFilter",
                            "variableName": "searchFilter"
                        },
                        {
                            "kind": "Variable",
                            "name": "statusFilter",
                            "variableName": "statusFilter"
                        }
                    ],
                    "kind": "FragmentSpread",
                    "name": "UserTableContainer_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v4 /*: any*/),
                (v3 /*: any*/)
            ],
            "kind": "Operation",
            "name": "UserTableContainerPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v10 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "multisite",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "featureFlags",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EmailConfiguration",
                            "kind": "LinkedField",
                            "name": "email",
                            "plural": false,
                            "selections": [
                                (v11 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Auth",
                            "kind": "LinkedField",
                            "name": "auth",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AuthIntegrations",
                                    "kind": "LinkedField",
                                    "name": "integrations",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "LocalAuthIntegration",
                                            "kind": "LinkedField",
                                            "name": "local",
                                            "plural": false,
                                            "selections": [
                                                (v11 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "allowRegistration",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "AuthenticationTargetFilter",
                                                    "kind": "LinkedField",
                                                    "name": "targetFilter",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "admin",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Organization",
                            "kind": "LinkedField",
                            "name": "organization",
                            "plural": false,
                            "selections": [
                                (v7 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EmailDomain",
                            "kind": "LinkedField",
                            "name": "emailDomainModeration",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "domain",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "newUserModeration",
                                    "storageKey": null
                                },
                                (v5 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "protectedEmailDomains",
                            "storageKey": null
                        },
                        (v5 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v12 /*: any*/),
                    "concreteType": "UsersConnection",
                    "kind": "LinkedField",
                    "name": "users",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v5 /*: any*/),
                                        (v6 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "username",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "email",
                                            "storageKey": null
                                        },
                                        (v10 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "UserStatus",
                                            "kind": "LinkedField",
                                            "name": "status",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "BanStatus",
                                                    "kind": "LinkedField",
                                                    "name": "ban",
                                                    "plural": false,
                                                    "selections": [
                                                        (v13 /*: any*/),
                                                        (v8 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "SuspensionStatus",
                                                    "kind": "LinkedField",
                                                    "name": "suspension",
                                                    "plural": false,
                                                    "selections": (v14 /*: any*/),
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "PremodStatus",
                                                    "kind": "LinkedField",
                                                    "name": "premod",
                                                    "plural": false,
                                                    "selections": (v14 /*: any*/),
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "WarningStatus",
                                                    "kind": "LinkedField",
                                                    "name": "warning",
                                                    "plural": false,
                                                    "selections": (v14 /*: any*/),
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "current",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "UserMembershipScopes",
                                            "kind": "LinkedField",
                                            "name": "membershipScopes",
                                            "plural": false,
                                            "selections": (v9 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "createdAt",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "deletedAt",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v12 /*: any*/),
                    "filters": [
                        "role",
                        "status",
                        "query"
                    ],
                    "handle": "connection",
                    "key": "UserTable_users",
                    "kind": "LinkedHandle",
                    "name": "users"
                }
            ]
        },
        "params": {
            "id": "6b112a5c4b277d027c4ce66c526c7b3a",
            "metadata": {},
            "name": "UserTableContainerPaginationQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '45c661bc7242b7fe159c2008a34d2be8';
export default node;
