import { Localized } from "@fluent/react/compat";
import React, { FunctionComponent, useCallback, useState } from "react";
import { graphql } from "react-relay";

import { useMutation, withFragmentContainer } from "coral-framework/lib/relay";

import { MemberBioContainer_user } from "coral-admin/__generated__/MemberBioContainer_user.graphql";

import cn from "classnames";
import SaveMemberBioMutation from "coral-admin/components/UserHistoryDrawer/SaveMemberBioMutation";
import ToggleMemberBioMutation from "coral-admin/components/UserHistoryDrawer/ToggleMemberBioMutation";
import { MAX_BIO_LENGTH } from "coral-common/common/lib/constants";
import { InvalidRequestError } from "coral-framework/lib/errors";
import { parseEmptyAsNull } from "coral-framework/lib/form";
import { validateMaxLength } from "coral-framework/lib/validation";
import CLASSES from "coral-stream/classes";
import RemainingCharactersContainer from "coral-stream/tabs/Comments/RemainingCharacters";
import {
  AlertTriangleIcon,
  CheckIcon,
  DeleteIcon,
  SvgIcon,
} from "coral-ui/components/icons";
import {
  Button,
  Flex,
  FormField,
  HorizontalGutter,
} from "coral-ui/components/v2";
import { CallOut, TextArea, ValidationMessage } from "coral-ui/components/v3";
import { FORM_ERROR, FormApi } from "final-form";
import { Field, Form } from "react-final-form";
import styles from "./MemberBioContainer.css";

interface Props {
  user: MemberBioContainer_user;
}

interface FormProps {
  bio: string;
}

const MemberBioContainer: FunctionComponent<Props> = ({ user }) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const saveMemberBio = useMutation(SaveMemberBioMutation);

  const closeSuccessMessage = useCallback(
    () => setShowSuccessMessage(false),
    []
  );

  const onSubmit = useCallback(
    async (input: FormProps, form: FormApi) => {
      try {
        await saveMemberBio({
          userID: user.id,
          bio: input.bio,
        });
      } catch (err) {
        if (err instanceof InvalidRequestError) {
          return err.invalidArgs;
        }

        return {
          [FORM_ERROR]: err.message,
        };
      }

      setShowSuccessMessage(true);

      return;
    },
    [saveMemberBio]
  );

  const toggleMemberBio = useMutation(ToggleMemberBioMutation);
  const onToggleBio = useCallback(
    (enabled: boolean) => {
      return toggleMemberBio({
        userID: user.id,
        enabled,
      });
    },
    [user]
  );

  return (
    <div>
      <HorizontalGutter spacing={2}>
        <Localized id="moderate-user-drawer-bio-title">
          <h3 className={styles.title}>Member Bio</h3>
        </Localized>

        <Form onSubmit={onSubmit} initialValues={{ bio: user.bio }}>
          {({
            handleSubmit,
            submitError,
            pristine,
            invalid,
            dirtySinceLastSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <HorizontalGutter spacing={4}>
                <FormField>
                  <Field
                    name="bio"
                    parse={parseEmptyAsNull}
                    validate={validateMaxLength(MAX_BIO_LENGTH)}
                  >
                    {({ input, meta }) => (
                      <>
                        <TextArea
                          name={input.name}
                          onChange={input.onChange}
                          value={input.value}
                          color="streamBlue"
                          disabled={!user.status.bio.enabled}
                        />
                        <div>
                          {meta.error && (
                            <ValidationMessage
                              meta={meta}
                              justifyContent="flex-end"
                            />
                          )}
                          <RemainingCharactersContainer
                            max={MAX_BIO_LENGTH}
                            value={input.value}
                          />
                        </div>
                      </>
                    )}
                  </Field>
                </FormField>
                {showSuccessMessage && (
                  <CallOut
                    color="success"
                    onClose={closeSuccessMessage}
                    className={cn(CLASSES.myUsername.form.successCallOut)}
                    icon={<SvgIcon size="sm" Icon={CheckIcon} />}
                    titleWeight="semiBold"
                    title={
                      <Localized id="profile-changeUsername-success">
                        <span>Bio has been updated</span>
                      </Localized>
                    }
                    aria-live="polite"
                  />
                )}
                {submitError && (
                  <CallOut
                    color="error"
                    className={CLASSES.myUsername.form.errorMessage}
                    icon={<SvgIcon size="sm" Icon={AlertTriangleIcon} />}
                    titleWeight="semiBold"
                    title={submitError}
                    role="alert"
                  />
                )}
              </HorizontalGutter>
              <div>
                <Flex
                  className={styles.actions}
                  direction="row"
                  itemGutter="half"
                >
                  <Button
                    className={cn(CLASSES.myUsername.form.saveButton)}
                    type="submit"
                    size="small"
                    color="regular"
                    disabled={
                      pristine ||
                      (invalid && !dirtySinceLastSubmit) ||
                      !user.status.bio.enabled
                    }
                  >
                    <span>Save</span>
                  </Button>
                  {user.status.bio.enabled ? (
                    <Button
                      color="alert"
                      size="small"
                      iconLeft
                      onClick={() => onToggleBio(false)}
                    >
                      <SvgIcon Icon={DeleteIcon} />
                      Disable bio
                    </Button>
                  ) : (
                    <Button
                      iconLeft
                      size="small"
                      onClick={() => onToggleBio(true)}
                    >
                      <SvgIcon Icon={CheckIcon} />
                      Enable bio
                    </Button>
                  )}
                </Flex>
              </div>
            </form>
          )}
        </Form>
      </HorizontalGutter>
    </div>
  );
};

const enhanced = withFragmentContainer<Props>({
  user: graphql`
    fragment MemberBioContainer_user on User {
      id
      bio
      status {
        bio {
          enabled
          history {
            bio
            operation
            createdAt
            createdBy {
              username
            }
          }
        }
      }
    }
  `,
})(MemberBioContainer);

export default enhanced;
